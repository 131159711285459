html {
  overflow-y: scroll;
}

.AF-Header {
  color: #355666;
  background-color: white;
  font-family: Sacramento;
  font-style: normal;
  text-align: center;
}

.AF-Header-Font {
  font-size: 120px;
}
@media (max-width: 1000px) {
  .AF-Header-Font {
    font-size: 70px;
  }
}

.AF-Nav-Div {
  background-color: white;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 11;
}
.AF-Nav-Bar {
  background-color: rgb(128, 128, 128, 0.05);
  top: 0;
  width: 100%;
  z-index: 10;
}

.AF-Nav-Item {
  text-align: center;
  margin: 0 auto;
}

.AF-Content {
  text-align: center;
  width: 100%;
}

.AF-Img {
  text-align: center;
  width: 100%;
  position: relative;
  background-color: white;
}

.AF-Img-Div {
  text-align: center;
  width: 100%;
  position: relative;
  background-color: white;
}

.AF-Img-Text {
  box-sizing: border-box;
  background-color: rgb(0, 0, 0, 0.5);
  position: absolute;
  padding: 1em;
  width: 100%;
  height: 100%;
  max-height: 100%;
  top: 0;
  text-align: center;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.AF-Stacked {
  margin-top: 5vw;
}

.LText {
  font-size: 6vw;
}

.MText {
  font-size: 4vw;
}

.SText {
  font-size: 2.5vw;
}

@media (max-width: 1000px) {
  .SText {
    font-size: 3.5vw;
  }
}

.NavText {
  font-size: 1em;
}

.RegText {
  font-size: 1.1em;
}

.RegTextLeft {
  text-align: left;
}

.MainFont {
  font-family: "Oxygen", sans-serif;
}

.AF-Col {
  text-align: center;
  padding: 1em;
}

.AF-Reg-Img {
  height: auto;
  width: auto;
}

.AF-Container {
  flex-direction: column;
  justify-content: space-around;
}

.AF-Button {
  padding: 20px 20px;
}

.AF-Loading {
  padding: 2em 2em;
  text-align: center;
}

p {
  margin-top: 1rem;
  width: 100%;
  align-items: center;
  height: fit-content;
}
